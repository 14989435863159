import React from 'react';
import { Modal } from 'antd';

const handleUseClick = () => {
  Modal.info({
    className: 'custom-modal',
    icon: null,
    okButtonProps: { style: { display: 'none' } },
    content: "",
    maskClosable: true,
  });
};

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo kaht13qb7ws-editor_css',
    children: 'https://cdn.tech-done.com/camera/钛动Logo_mini.svg',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#service',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>产品服务</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#business',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>合作商家</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#aboutus',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0 kahnknv9yh9-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper kahv3zb0awo-editor_css' },
  title: {
    className: 'banner0-title k9l6ysxfgsc-editor_css',
    children: 'https://cdn.tech-done.com/camera/logo.png',
  },
  content: {
    className: 'banner0-content k9mfci90ss-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>赋能新商业的技术解决方案</p>
            <p>上海钛动网络科技</p>
          </span>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button kahv30yleb-editor_css',
    children: 'Learn More',
    href: '',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务', id:'service' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>新零售中台</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      从传统电商、ERP到小程序商城，对接天猫、京东等30余家电商平台，统一进销存及CRM管理
                    </p>
                  </span>
                </span>
              ),
              className: 'k9mfwcewwz-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>新商业逻辑</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      私域运营模块，与CRM、订单系统完全打通，实现360度客户达标，20倍提升在线销售能力
                    </p>
                  </span>
                </span>
              ),
              className: 'k9mfwj25r4r-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>新科技赋能</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>AR、VR、3D展示能力，天猫首家上线AR小程序认证服务商</p>
                </span>
              ),
              className: 'k9mfxehsmva-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>画饼的很多，一起坐下来陪你品尝的很少</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      所有钛动产品，均有资深实施顾问根据实际需求进行系统选型，一对一现场指导，并在数周至数月内，与合作伙伴共同落地
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        钛动自2011年创立以来，陪伴数十位伙伴共同运营、成长，共创数十亿GMV，终端点位10W+
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content~kanldr24xn7',
        className: '',
        children: (
          <span>
            <p>
              选择我们，你可以专注于品牌、产品、企业管理本身，系统的事情，交给我们
            </p>
          </span>
        ),
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '#',
        children: (
          <span>
            <span>
              <span>
                <p>了解更多</p>
              </span>
            </span>
          </span>
        ),
      },
    },
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1 k9v3f2ooko-editor_css',
        children: (
          <span>
            <p>新零售中台</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>
              钛动一站式提供企业在线上、线下经营过程中所需要的前台商城/POS、中台订单/CRM系统以及后台仓储/财务管理系统
            </p>
          </span>
        ),
      },
      {
        name: 'content~kanlncw8rgs',
        className: '',
        children: (
          <span>
            <p>
              可根据合作伙伴现有IT建设情况及需求，灵活配置，各个系统均与主流平台对接完成，并可二次深度开发
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkq23a6q-editor_css',
              children: 'https://cdn.tech-done.com/camera/OMS.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>OMS</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      集中处理企业订单，汇集天猫、京东、官方商城、以及手工订单
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkti9wair-editor_css',
              children: 'https://cdn.tech-done.com/camera/WMS.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>WMS</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        集中管理企业库存，可管理多地多仓，精细化库位、岗位管理
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mku64perk-editor_css',
              children: 'https://cdn.tech-done.com/camera/POS.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>POS</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>丰富的线下收银方式，聚合各个支付平台，兼容扫码、刷脸</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkup2nqd8-editor_css',
              children: 'https://cdn.tech-done.com/camera/eshop.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>eShop</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>企业官方商城，打造品牌专属的购物体验和选购流程</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkvstnzyq-editor_css',
              children: 'https://cdn.tech-done.com/camera/移动商城.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>移动商城</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>基于Html 5 技术的商城，支持移动设备的购物场景</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkwjlgxpq-editor_css',
              children: 'https://cdn.tech-done.com/camera/小程序.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>小程序商城</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>支持微信、支付宝、百度等多平台小程序商城，流畅购物体验</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkwz8j26h-editor_css',
              children: 'https://cdn.tech-done.com/camera/CRM.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>CRM</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>支持客户RFM标签管理，配合钛动私域管理，微信、短信多触点</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k9mkxduvaor-editor_css',
              children: 'https://cdn.tech-done.com/camera/平台对接.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>私域管理系统</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>20倍提升销售人员效率，通过免费的多次触达客户，激活老客</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        id:'philosophy',
        name: 'title',
        children: (
          <span>
            <p>新商业逻辑</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content~kai2pkw2q',
        className: 'kai2plzzavq-editor_css',
        children: (
          <span>
            <span>
              <p>告别昂贵的公域红海，打造品牌/渠道自有的客户池，拥抱私域红利</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'img-wrapper kai3714wm9g-editor_css',
    children: [
      {
        name: 'block1',
        className: 'block kai2wcoo1hs-editor_css',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content kai2z9ipns7-editor_css' },
          img: {
            children: 'https://cdn.tech-done.com/camera/私域图.svg',
            className: 'kai2r49sv0a-editor_css',
          },
        },
      },
    ],
  },
};
export const Content41DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: 'k9l364tb2l-editor_css' },
  textWrapper: {
    className: 'content4-text kcegvliqkdf-editor_css',
    xs: 24,
    md: 10,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>新科技赋能</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: (
          <span>
            <p>钛动的前沿科技能力很炫，但我们从不为了炫而炫</p>
          </span>
        ),
      },
      {
        name: 'content~kanmqmokjem',
        className: '',
        children: (
          <span>
            <span>
              <p>
                我们选择契合于品牌形象并能极大提升购物体验的新技术，以实现ROI的提升
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video k9l349a8il-editor_css',
    children: {
      video: 'https://cdn.tech-done.com/202007081013119bba95383.mp4',
      image: 'https://cdn.tech-done.com/202007081013119bba95383.mp4?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast',
    },
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: require('../assets/淘宝.svg'),
          className: 'content4-icon',
        },
        title: { className: 'content4-title', children: '天猫、淘宝完美适配' },
        content: {
          className: 'content4-content',
          children:
            '可以配置在店铺首页、商品详情页，手淘猫客原声小程序，流畅体验。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content4-icon',
          children: require('../assets/模型渲染.svg'),
        },
        title: { className: 'content4-title', children: '数十万面 模型渲染能力' },
        content: {
          className: 'content4-content',
          children:
            '精致到手表的细微零件及曲面，经得起微距放大考验。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content4-icon',
          children: require('../assets/360.svg'),
        },
        title: { className: 'content4-title', children: '360° 三维动画展示能力' },
        content: {
          className: 'content4-content',
          children:
            '实现结构爆炸、内部原理动画讲解等多种酷炫展示方式。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content4-icon',
          children: require('../assets/营销.svg'),
        },
        title: { className: 'content4-title', children: '丰富的营销能力' },
        content: {
          className: 'content4-content',
          children:
            '可对接优惠券、天猫会员卡等营销能力，结合CRM，做到千人千面。',
        },
      },
    ],
  },
  
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5 k9mlhpmiznc-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1', id:'business' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              钛动自2011年创立以来，陪伴数十位伙伴共同运营、成长，共创数十亿GMV，终端点位10W+
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/casio.svg',
            className: 'k9mlgmzqvx-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>CASIO</p>
                      <p>天猫旗舰店AR、3D展示小程序</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'k9mljrw3n2k-editor_css',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/rinnai.svg',
            className: 'k9mpagup3sp-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>林内</p>
                    <p>官方小程序商城</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/大宝.svg',
            className: 'k9v6qlw9ati-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>大宝</p>
                          <p>
                            AR互动APP<br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/unna.svg',
            className: 'k9v6tb9mud-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>UNNA</p>
                  <p>
                    小美业10000+终端解决方案<br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/citysuper.svg',
            className: 'k9v6y7ec37t-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>Citysuper</p>
                  <p>
                    天猫会员通，实现全域会员营销<br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/hugclub.svg',
            className: 'k9v6zzxa6z8-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>Hugclub</p>
                    <p>
                      受到资本青睐的原创潮鞋品牌<br />
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/盛世齐家.svg',
            className: 'k9v70nmq1ug-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>盛世齐家</p>
                  <p>
                    余世维老师领军的知识付费平台<br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://cdn.tech-done.com/camera/foreo.svg',
            className: 'k9v722cz9k-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>Foreo</p>
                  <p>
                    实现跨境Travel Retail系统<br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        id:'aboutus',
        name: 'title',
        children: (
          <span>
            <p>强大的研发及技术支持</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>经验源于实践</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        id: 'applets',
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>钛动新商业解决方案 - 小程序商城</p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <p>7天体验版</p>
                </span>
              </span>
            ),
          },
          money: { className: 'pricing1-money', children: '¥0' },
          content: {
            className: 'pricing1-content kannn1moe6-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>小程序商城基本版</p>
                            <p>全部功能</p>
                            <p>
                              <br />
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              <br />
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'javascript:;',
                children: '免费试用'
              },
            },
            onClick: handleUseClick,
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>单店 旗舰版</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <span>
                    <p>¥4998/年</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content kaw5wwjnkg-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>小程序商城</p>
                        <p>商品分类、属性管理</p>
                        <p>订单、支付、售后</p>
                        <p>微信直播、导购专属名片</p>
                        <p>多级分销员体系</p>
                        <p>无忧上线支持</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'javascript:;',
                children: '立即购买',
              },
            },
            onClick: handleUseClick,
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>连锁 云销版</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <p>¥29998/年</p>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>经销商独立小程序 1998元/店</p>
                      <p>
                        总部供应链管理、<span>全链路物流追踪</span>
                      </p>
                      <p>
                        <span>支持采销、代理、一件代发模式</span>
                      </p>
                      <p>多种回款方式、返利政策</p>
                      <p>总部营销库、知识库</p>
                      <p>远程巡店、行业定制需求</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'javascript:;',
                children: '立即购买',
              },
            },
            onClick: handleUseClick,
          },
        },
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>钛动新商业解决方案 - 品牌知识中心</p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
      {
        name: 'content~kano4s6knm7',
        className: '',
        children: (
          <span>
            <p>一站式解决线下销售/导购向线上转型，将线下流量沉淀到品牌私域</p>
          </span>
        ),
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          name: 'empty',
          childWrapper: {
            children: [
              { name: 'name', children: ' ' },
              { name: 'content', children: ' ' },
            ],
          },
        },
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>7天免费版</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <p>1个体验账号</p>
                    </span>
                  </span>
                ),
              },
              { name: 'button', children: { href: 'javascript:;', children: '免费试用' }, onClick: handleUseClick },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <p>团队版</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>适合5-50人</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              { name: 'button', children: { href: 'javascript:;', children: '立即购买' }, onClick: handleUseClick },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>企业版</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>适合50-200人</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: { href: 'javascript:;', type: 'primary', children: '立即购买' },
                onClick: handleUseClick,
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited',
          key: 'unlimited',
          name: 'unlimited',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>KA渠道版</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <p>适合200人以上</p>
                  </span>
                ),
              },
              { name: 'button', children: { href: 'javascript:;', children: '立即购买' }, onClick: handleUseClick, },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>单账号收费</p>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <p>¥ 0</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <p>¥ 3000元/年/账号</p>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>¥ 4800元/年/账号</p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>¥ 9800元/年/账号</p>
                </span>
              ),
              name: 'content3',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <span>品牌知识中心</span>
                          <br />
                        </p>
                        <p>（样本、手册、官方下载中心）</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>1GB</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>10GB/账号</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>20GB/账号</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <p>50GB/账号</p>
                  </span>
                </span>
              ),
              name: 'content3',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>私域 / 朋友圈基础素材</p>
                              <p>（文字、图片、视频）</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content0',
              children: (
                <span>
                  <p>不限数量</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>不限数量</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>不限数量</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <p>不限数量</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>生成专属素材</p>
                  <p>（自动水印、二维码等）</p>
                </span>
              ),
            },
            {
              children: '-',
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>总部讲师知识付费</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content0',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>私域管理系统</p>
                    <p>（总部统一维护私域推送，私单防控）</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content0',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo kai0zwsdzi-editor_css',
          children: 'https://cdn.tech-done.com/camera/钛动Logo_底部.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>上海钛动网络科技有限公司</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'kai1k4suhp9-editor_css',
            },
            {
              name: 'title~kai1m6tu7i8',
              className: 'kai1mguqu5a-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#applets',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>小程序商城</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#business',
              children: (
                <span>
                  <span>
                    <p>品牌知识中心</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#philosophy',
              children: (
                <span>
                  <span>
                    <p>新商业理念</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            {
              href: '#aboutus',
              name: 'link1',
              children: (
                <span>
                  <span>
                    <p>联系我们</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
            {
              href: '#business',
              name: 'link0',
              children: (
                <span>
                  <p>客户案例</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>
          <span>
            ©2020 by 上海钛动网络科技有限公司&nbsp;All Rights Reserved
          </span>
        </p>
      </span>
    ),
  },
};
